import Dialog from "./index";
import React, { useRef } from "react";
import { Error } from "@mui/icons-material";
import Button from "../Button";

type Props = {
  closeDialog: () => void;
  alertText: string;
  alertType: "info" | "confirm";
  confirmText?: string;
  onConfirm?: () => void;
  isConfirmLoading?: boolean;
};
const AlertDialog: React.FC<Props> = ({
  closeDialog,
  alertText,
  alertType,
  confirmText,
  onConfirm,
  isConfirmLoading,
}) => {
  const dialogRef = useRef<HTMLDivElement>(null);

  const handleCloseWithAnimation = () => {
    dialogRef.current?.classList.add("animate-scale-down-anim");
    setTimeout(() => {
      closeDialog();
    }, 200);
  };

  return (
    <Dialog>
      <div
        ref={dialogRef}
        className="bg-white rounded-lg px-4 py-2 min-w-[50vw] animate-scale-anim"
      >
        <div className="flex w-full justify-center my-4">
          <Error
            fontSize={"large"}
            className="text-red-600 text-lg"
            style={{ fontSize: 72 }}
          />
        </div>
        <h6 className="text-center text-gray-700">{alertText}</h6>
        {alertType === "confirm" ? (
          <div className="flex items-center justify-between mt-4">
            <Button
              type="button"
              title="Cancel"
              className="w-full mr-3 justify-center"
              onClick={handleCloseWithAnimation}
            />
            <Button
              type="button"
              title={confirmText || "Yes"}
              className="w-full ml-3 bg-red-600 justify-center hover:!bg-red-800"
              onClick={onConfirm}
              isLoading={isConfirmLoading}
            />
          </div>
        ) : null}
      </div>
    </Dialog>
  );
};

export default AlertDialog;
