import MiniChart from "../../components/Charts/MiniChart";
import BarChart from "../../components/Charts/BarChart";
import MessageBalanceWarning from "../../components/MessageBalanceWarning";

const chartData1 = [1200, 2212, 3215, 3212, 5441, 764, 4212];
const chartData2 = [5, 2, 1, 0, 4, 0, 4];
const chartData3 = [1, 0, 4, 1, 2, 5, 2];
const chartData4 = [0, 0, 0, 0, 0, 0, 0];

const BarChart1 = {
  labels: [
    "Pantolon",
    "Gömlek",
    "Kaban",
    "T-Shirt",
    "Elbise",
    "Mont",
    "Ceket",
    "Yelek",
    "Bluz",
    "Tayt",
  ],
  datasets: [
    {
      label: "User interest",
      data: [5100, 2000, 300, 1420, 2211, 1200, 800, 2311, 320, 2832, 1230],
      backgroundColor: "#00A2E8",
      borderColor: "#0ea5e9",
      tension: 0.4,
      barThickness: 12,
      borderRadius: 12,
    },
  ],
};

const BarChart2 = {
  labels: [
    "Premium palazzo pantolon",
    "Çizgili crop blazer",
    "Bisiklet yaka transparan top - Premium collection",
    "Saten midi etek",
    "Yarım balıkçı fermuarlı kazak gri",
    "Dad fit klasik pantolon",
    "Premium palazzo pantolon",
    "Çizgili crop blazer",
    "Bisiklet yaka transparan top - Premium collection",
    "Saten midi etek",
    "Yarım balıkçı fermuarlı kazak gri",
    "Dad fit klasik pantolon",
    "Premium palazzo pantolon",
    "Çizgili crop blazer",
    "Bisiklet yaka transparan top - Premium collection",
    "Saten midi etek",
    "Yarım balıkçı fermuarlı kazak gri",
    "Dad fit klasik pantolon",
    "Premium palazzo pantolon",
    "Çizgili crop blazer",
    "Bisiklet yaka transparan top - Premium collection",
    "Saten midi etek",
    "Yarım balıkçı fermuarlı kazak gri",
    "Dad fit klasik pantolon",
  ],
  datasets: [
    {
      label: "biens.ai tarafından önerilme",
      data: [
        5100, 2000, 300, 1420, 2211, 1398, 20, 5100, 2000, 300, 1420, 2211,
        1398, 20, 5100, 2000, 300, 1420, 2211, 1398, 20, 5100, 2000, 300, 1420,
        2211, 1398,
      ],
      backgroundColor: "#7dd3fc",
      order: 2,
      tension: 0.4,
      barThickness: 12,
      borderRadius: 12,
    },
    {
      label: "müşteri tıklaması",
      data: [
        100, 200, 30, 142, 221, 138, 2, 100, 200, 30, 142, 221, 138, 2, 100,
        200, 30, 142, 221, 138, 2, 100, 200, 30, 142, 221, 138, 2, 100, 200, 30,
        142, 221, 138,
      ],
      backgroundColor: "#00A2E8",
      order: 1,
      tension: 0.4,
      barThickness: 12,
    },
  ],
};

const BarChart3 = {
  labels: ["TR", "USA", "DE", "FR", "HR", "CA"],
  datasets: [
    {
      label: "Users by countries",
      data: [5100, 2000, 300, 1420, 2211, 1398, 20],
      backgroundColor: "#00A2E8",
      barThickness: 18,
      borderRadius: 12,
    },
  ],
};

const Analytics = () => {
  return (
    <div className="lg:container lg:mx-auto px-3 py-4 pt-[calc(50px+1rem)] lg:pt-4">
      <MessageBalanceWarning />
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 lg:mb-5">
        <MiniChart title="Total Visitors" chartData={chartData1} />
        <MiniChart title="Total Unique Visitors" chartData={chartData2} />
        <MiniChart title="Messages by biens.ai" chartData={chartData3} />
        <MiniChart
          title="Product visit with biens.ai messages"
          chartData={chartData4}
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 my-5">
        <BarChart
          title={"En çok sorulan kategoriler"}
          data={BarChart1}
          direction="x"
        />
        <BarChart
          title={"biens.ai tarafından önerilenler"}
          data={BarChart2}
          direction="x"
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-5">
        <BarChart title={"Users by location"} data={BarChart3} direction="y" />
        <BarChart title={"Users by language"} data={BarChart3} direction="y" />
      </div>
    </div>
  );
};

export default Analytics;
