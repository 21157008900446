import React, { useState } from "react";
import Button from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import ProfilePhoto from "../../components/ProfilePhoto";
import { removeMember } from "../../store/slices/teamSlice";
import { REQUEST_REMOVE_TEAM_MEMBER } from "../../api/requests";
import { toast } from "react-toastify";
import NewMemberDialog from "../../components/Dialog/NewMemberDialog";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import { Delete, Edit } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Team = () => {
  const dispatch = useDispatch();
  const { team } = useSelector((state: RootState) => state.team);
  const { company } = useSelector((state: RootState) => state.auth);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [addMemberDialog, setAddMemberDialog] = useState(false);

  const handleRemoveTeamMember = (memberId: string) => {
    setRemoveLoading(true);
    REQUEST_REMOVE_TEAM_MEMBER(memberId)
      .then(() => {
        setRemoveLoading(false);
        dispatch(removeMember(memberId));
        toast.success("Team member removed");
      })
      .catch((err) => {
        console.log("error happen when removing team member", err);
        toast.error("Team member could not removed");
      });
  };

  const rows = team
    .filter((item) => item._id !== company.userId)
    .map((member) => ({
      id: member._id,
      email: member.email,
      name: member.name,
      permission: member.permission,
      photoUrl: member.photoUrl,
    }));

  return (
    <div className="container mx-auto h-full lg:px-3 lg:py-4">
      {addMemberDialog ? (
        <NewMemberDialog closeDialog={() => setAddMemberDialog(false)} />
      ) : null}
      <div className="bg-white h-full rounded-lg shadow p-4">
        <div className="flex items-center justify-between mb-2 pt-[60px] px-2 lg:pt-0 lg:px-0 mb-5">
          <h6 className="font-bold text-gray-700">My Team</h6>
          <Button
            title="Add Team Member"
            type="button"
            size="sm"
            onClick={() => setAddMemberDialog(true)}
          />
        </div>
        <div className="flex w-full h-dvh lg:h-max">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="left" component="th">
                    Name
                  </TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="left">Permissions</TableCell>
                  <TableCell align="center" component="th">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <StyledTableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell scope="row" width={48}>
                      <ProfilePhoto
                        name={row.name}
                        photoUrl={row.photoUrl}
                        size={36}
                      />
                    </TableCell>
                    <TableCell scope="row">{row.name}</TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">{row.permission}</TableCell>
                    <TableCell align="center">
                      <IconButton aria-label="delete" color="default">
                        <Edit />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        color="error"
                        onClick={() => handleRemoveTeamMember(row.id)}
                        disabled={removeLoading}
                      >
                        {removeLoading ? (
                          <CircularProgress
                            className={`me-2 my-auto`}
                            color={"error"}
                            size={18}
                          />
                        ) : (
                          <Delete />
                        )}
                      </IconButton>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default Team;
