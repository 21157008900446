export const ChartPastOptions = [
  {
    label: "7 days",
    value: "7",
  },
  {
    label: "14 days",
    value: "14",
  },
  {
    label: "30 days",
    value: "30",
  },
];
